<template>
  <div>
    <b-row class="align-items-center mb-2">
      <b-col cols="12" lg="6">
        <div class="d-flex pl-2 align-items-center">
          <b-img class="filter-image" :class="filter.sort_type == 'asc' ? 'rotate-180' : ''"
            :src="require('@/assets/images/icons/Icon-order-list-down.svg')" alt="Icon-order-list-down" role="button"
            @click="
              filter.sort_type == 'asc'
                ? (filter.sort_type = 'desc')
                : (filter.sort_type = 'asc')
              " />
          <div class="search__input w-100 pl-2 pr-0 mr-0">
            <b-input-group>
              <b-form-input v-model="filter.search" placeholder="" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="6" class="d-flex align-items-center" style="gap: 6px">
        <FilterButton @filterBase="getFilter" :is_transaction="true" :get-data="getData" />
        <b-button variant="primary" @click="filter = {}">
          Reset
        </b-button>
        <b-button variant="primary" @click="exportReport">
          Export Data
        </b-button>
        <b-button variant="primary" @click="addCabang">
          Tambah Cabang
        </b-button>
      </b-col>
    </b-row>

    <b-card-code no-body>
      <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table b-table table-fixed">
          <thead>
            <tr>
              <th>Nama Cabang</th>
              <th>Nama Toko</th>
              <th>Kode Cabang</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-if="isLoading">
              <td colspan="5" class="text-center">
                <b-spinner label="Loading..." variant="primary" />
              </td>
            </tr> -->
            <tr v-for="item, index in result.data" :key="`table-${index}`">
              <td>{{ item.name || '-' }}</td>
              <td>{{ item.merchant ? item.merchant.name : '-' }}</td>
              <td>{{ item.code_branch }}</td>
              <td class="text-right">
                <div class="btn-group" role="group" aria-label="Basic example">
                  <b-button variant="info" size="sm" @click="editItem(item.uuid)">
                    Lihat Detail
                  </b-button>
                </div>
              </td>
            </tr>
            <tr v-if="result.total == 0">
              <td colspan="5" class="text-center">
                Data is empty.
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-1">
          <div class="col-6 pl-2">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col-6 pr-2">
            <pagination :data="result" :limit="4" align="right" @pagination-change-page="getData" />
          </div>
        </div>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import FilterButton from "@/components/BaseFilter.vue";
import {
  BRow,
  BCol,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

import moment from "moment";
import FileSaver from "file-saver";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BButton,
    BRow,
    BCol,
    FilterButton,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    getData: {
      type: Function,
    },
    getFilter: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    addCabang: {
      type: Function,
    },
    result: {
      type: Object,
    },
    getDetail: {
      type: Function,
    },
    detailResult: {
      type: Object,
    },
  },
  data() {
    return {
      progressValue: 35,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Nama Cabang",
          field: "name",
        },
        {
          label: "Nama Toko",
          field: "merchant.name",
        },
        {
          label: "Kode Cabang",
          field: "code_branch",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      items: [],
      search: "",
      filter: {
        search: "",
        sort_type: "desc",
        per_page: 10,
      },
      isLoadingExport: false,
    };
  },
  watch: {
    filter: {
      handler(value) {
        this.$emit("filterBase", value);
      },
      deep: true,
    },
  },
  methods: {
    // goToDetail(uuid) {
    //   this.getDetail(uuid);
    //   this.$bvModal.show("modal-cabang");
    // },
    async exportReport() {
      // const queryParams = filter

      const response = await this.$http.get(
        "/super-admin/merchantBranch/export",
        {
          responseType: "blob",
          // params: queryParams,
        }
      );

      const dateDownload = new Date();

      const timestamp = moment(dateDownload).format("YYYYMMDD");
      let fileName = "";

      fileName = `daftar-cabang-${timestamp}.xlsx`;
      FileSaver.saveAs(response.data, fileName);
      // console.log(response, ' report')
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
