<template>
  <b-modal id="modal-export-cabang" size="lg" title="Unduh Cabang" no-close-on-backdrop>
    <div>
      <b-row class="p-2">
        <b-col cols="12" class="mb-2 custom__form--input">
          <b-form-group>
            <label for="v-merchant">Cabang</label>
            <v-select id="v-merchant" v-model="formPayload.merchant_uuid" placeholder="Pilih Cabang" label="title"
              :options="options" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="button__group">
      <b-button id="button--cancel__transaction" class="button__cancel" @click="$bvModal.hide('modal-export-cabang')">
        Batal
      </b-button>
      <b-button id="button--save__transaction" variant="primary" @click="exportReport(formPayload)">
        Simpan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";

export default {
  data() {
    return {
      formPayload: {
        merchant_uuid: '',
      },
    };
  },
  components: {
    BFormGroup,
    BButton,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    BFormSelect,
  },
  props: {
    getData: {
      type: Function,
    },
    result: {
      type: Object,
    },
    options: {
      type: Array,
    },
    getDetail: {
      type: Function
    },
    exportReport: {
      type: Function
    }
  },
  created() {

  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>