<template>
  <b-modal id="modal-cabang" size="lg" :title="editUuid !== null ? 'Detail Cabang' : 'Tambah Cabang'">
    <div class="form-group">
      <label for="v-nama">Nama <span class="text-danger">*</span></label>
      <b-form-group>
        <b-form-input id="v-nama" v-model="formPayload.name" type="text" placeholder="Nama" class="custom__input" />
      </b-form-group>
    </div>

    <div class="form-group">
      <label for="v-alamat">Alamat Cabang</label>
      <b-form-group>
        <b-form-input id="v-alamat" v-model="formPayload.address" type="text" placeholder="Alamat"
          class="custom__input" />
      </b-form-group>
    </div>

    <div class="form-group">
      <label for="v-kota">Kota</label>
      <b-form-group>
        <b-form-input id="v-kota" v-model="formPayload.city" type="text" placeholder="Kota" class="custom__input" />
      </b-form-group>
    </div>

    <div class="form-group">
      <label for="v-postal">Kode Pos</label>
      <b-form-group>
        <b-form-input id="v-postal" v-model="formPayload.postal_code" type="text" placeholder="Kode Pos"
          class="custom__input" />
      </b-form-group>
    </div>

    <div class="form-group">
      <label for="v-phone">No. Handphone <span class="text-danger">*</span></label>
      <b-row>
        <b-col cols="4" class="pr-0">
          <v-select v-model="formPayload.selectedCountry" :label="currentLabel" :options="countryList"
            :clearable="false" @keyup.stop.native="searchCountry">
            <template #selected-option="{ country_flag, dial_code_preview }">
              <div class="d-flex align-items-center" style="gap: 6px">
                <img :src="country_flag" :alt="dial_code_preview" style="width: 24px; height: 24px" />
                <p class="size12 mb-0">({{ dial_code_preview }})</p>
              </div>
            </template>
            <template #option="{ country_flag, dial_code_preview }">
              <div class="d-flex align-items-center" style="gap: 8px">
                <img :src="country_flag" :alt="dial_code_preview" style="width: 24px; height: 24px" />
                <p class="mb-0">{{ dial_code_preview }}</p>
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col cols="8">
          <b-form-group class="mb-0">
            <b-form-input id="v-phone" v-model="formattedPhone" type="tel" placeholder="No. Handphone"
              class="custom__input" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div class="form-group" v-if="editUuid === null">
      <label for="v-toko">Toko</label>
      <b-form-group>
        <v-select id="v-toko" v-model="formPayload.merchant_uuid" label="name" :options="optionsToko" placeholder="Toko"
          :reduce="(optionsToko) => optionsToko.uuid" @search="onSearchToko" />
      </b-form-group>
    </div>

    <div class="form-group">
      <label for="v-branch">Kode Cabang</label>
      <b-form-group>
        <b-form-input id="v-branch" v-model="formPayload.code_branch" type="text" placeholder="Kode Cabang"
          class="custom__input" />
      </b-form-group>
    </div>

    <div class="form-group">
      <label for="v-renewal">Status Berlangganan</label>
      <b-form-group>
        <b-form-select id="v-renewal" v-model="formPayload.renewal_status" class="custom__input">
          <b-form-select-option :value="null">Pilih Status Berlangganan</b-form-select-option>
          <b-form-select-option value="subscribe">Subscribe</b-form-select-option>
          <b-form-select-option value="trial">Trial</b-form-select-option>
          <b-form-select-option value="free">Gratis</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>

    <div class="form-group" v-if="editUuid !== null">
      <label>Tanggal Pembayaran Xendit</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ formPayload.xendit_payment_date || '-' }}
      </b-col>
    </div>

    <div class="form-group">
      <label for="v-renewalEnd">Tanggal Berakhir</label>
      <b-form-group>
        <b-form-datepicker id="v-renewalEnd" v-model="formPayload.renewal_end_date" class="custom__input"
          placeholder="Tanggal Berakhir" locale="id"></b-form-datepicker>
      </b-form-group>
    </div>

    <div class="form-group" v-if="editUuid !== null">
      <label>Sisa Waktu Berlangganan</label>
      <b-col cols="12" class="custom__input mb-2 pt-1">
        {{ formPayload.remaining_renewal_days }} Hari
      </b-col>
    </div>
    <template #modal-footer="{}">
      <b-button class="px-3" variant="primary" @click="editUuid !== null ? updateItem() : createItem()">
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";

import BaseInput from "@/components/BaseInput.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BContainer,
    BaseInput,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    BFormDatepicker,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    getData: {
      type: Function,
    },
    result: {
      type: Object,
    },
    // getDetail: {
    //   type: Function,
    // },
    detailResult: {
      type: Object,
    },
    editItem: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    editId: {
      type: "",
    },
    cleanUpForm: {
      type: Function,
    },
  },
  watch: {
    "formPayload.selectedCountry"(newVal, oldVal) {
      const oldDialCode = oldVal ? oldVal.dial_code_value : "";
      const newDialCode = newVal.dial_code_value;
      if (this.formPayload.phone.startsWith(oldDialCode)) {
        this.formPayload.phone =
          newDialCode + this.formPayload.phone.replace(oldDialCode, "");
      }
    },
    editId(value) {
      this.editUuid = value;
    },
    formData(value) {
      this.formPayload = value;
    },
    formPayload: {
      handler(value) {
        this.$emit("getPayload", value);
      },
      deep: true,
    },
  },
  computed: {
    currentLabel() {
      return /^\d+$/.test(this.searchQuery)
        ? "dial_code_value"
        : "country_name";
    },
    dialCode() {
      const selectedCountry = this.countryList.find(
        (country) =>
          country.country_code ===
          this.formPayload.selectedCountry?.country_code
      );
      return selectedCountry ? selectedCountry.dial_code_value : "";
    },
    formattedPhone: {
      get() {
        if (!this.formPayload.phone) {
          return "";
        }
        const regex = new RegExp(`^${this.dialCode}`);
        return this.formPayload.phone.replace(regex, "");
      },
      set(value) {
        if (value.startsWith("0")) {
          value = value.substring(1);
        }
        this.formPayload.phone = this.dialCode + value;
      },
    },
  },
  data() {
    return {
      editUuid: null,
      formPayload: {
        address: "",
        city: "",
        postal_code: "",
        phone: "",
        phone_country: "",
        code_branch: "",
        renewal_status: "",
        xendit_payment_date: "",
        renewal_end_date: "",
        remaining_renewal_days: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      },
      status: [
        {
          name: "Subscribe",
          value: "subscribe",
        },
        {
          name: "Trial",
          value: "trial",
        },
        {
          name: "Gratis",
          value: "free",
        },
      ],
      searchQuery: "",
      countryList: [],
      optionsToko: [],
    };
  },
  mounted() {
    this.getCountry();
    this.getDataToko();
  },
  methods: {
    ...mapActions({
      getDataTokoAction: "toko/getData",
    }),
    getPhoto(value) {
      this.formPayload.logo = value.photo;
    },
    directPage() {
      //   this.cleanUpForm()
      this.$bvModal.hide("modal-cabang");
    },

    onSearchToko(search, loading) {
      if (search.length) {
        loading(true);
        this.searchToko(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsToko = [];
          this.getDataToko();
          loading(false);
        }, 500);
      }
    },
    searchToko: _.debounce((loading, search, vm) => {
      vm.getDataTokoAction({
        params: {
          search,
        },
      }).then((response) => {
        vm.optionsToko = response.data;
        loading(false);
      });
    }, 300),
    async getDataToko() {
      const response = await this.getDataTokoAction({
        params: "",
      });
      this.optionsToko = response.data;
    },

    searchCountry: _.debounce(function (e) {
      const search = e.target.value;
      this.searchQuery = search;
      this.getCountry(search);
    }, 300),
    getCountry(search) {
      const isDialCode = /^\d+$/.test(search);
      const params = isDialCode ? { search: search } : { search: search };

      this.$http
        .get("/country-phone-codes?per_page=500", { params })
        .then((response) => {
          this.countryList = response.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
</style>
