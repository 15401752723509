<template>
  <div class="section">
    <TableCabang :get-data="getData" :result="result" :detail-result="detailResult" :addCabang="addCabang"
      :edit-item="editItem" :get-filter="getFilter" @filterBase="getFilter" />
    <ModalCabang :get-data="getData" :result="result" :detail-result="detailResult" :form-data="formPayload"
      :edit-item="editItem" :update-item="updateItem" :create-item="createItem" :editId="editId"
      :cleanUpForm="cleanUpForm" @getPayload="getPayload" />
    <!-- <ModalExportCabang
      :get-data="getData"
      :result="result"
      :get-detail="getDetail"
      :options="options"
      :export-report="exportReport"
    /> -->
  </div>
</template>

<script>
import TableCabang from "@/components/Cabang/TableCabang.vue";
import ModalCabang from "@/components/Cabang/Modal/ModalCabang.vue";
import ModalExportCabang from "@/components/Cabang/Modal/ModalExportCabang.vue";

import moment from "moment";
import FileSaver from "file-saver";

import { successNotification, errorNotification } from "@/auth/utils";

export default {
  title() {
    return "Cabang";
  },
  components: {
    TableCabang,
    ModalCabang,
    ModalExportCabang,
  },
  data() {
    return {
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
      },
      formPayload: {
        address: "",
        city: "",
        postal_code: "",
        phone: "",
        phone_country: "",
        code_branch: "",
        renewal_status: "",
        xendit_payment_date: "",
        renewal_end_date: "",
        xendit_payment_date: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      },
      editId: null,
      currentPage: 1,
      isLoading: true,
      result: {},
      detailResult: {},
      validations: [],
      options: [],
      filterExport: {
        merchant_uuid: "",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getFilter(value) {
      this.$router.replace({ query: "" });
      this.filter = {
        ...value,
      };
      this.getData();
    },
    async getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$store
        .dispatch("cabang/getData", { uuid: "", params: queryParams })
        .then((result) => {
          this.isLoading = false;
          this.result = result.data.data;
          this.currentPage = result.data.data.current_page;
          for (let index = 0; index < result.data.data.data.length; index++) {
            const element = result.data.data.data[index];
            this.options.push({
              title: element.name,
              label: element.uuid,
            });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    getPayload(value) {
      this.formPayload = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        address: "",
        city: "",
        postal_code: "",
        phone: "",
        phone_country: "",
        code_branch: "",
        renewal_status: "",
        xendit_payment_date: "",
        renewal_end_date: "",
        remaining_renewal_days: "",
        merchant_uuid: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      };
    },
    addCabang() {
      this.editId = null;
      this.formPayload = {
        address: "",
        city: "",
        postal_code: "",
        phone: "",
        phone_country: "",
        code_branch: "",
        renewal_status: "",
        xendit_payment_date: "",
        renewal_end_date: "",
        remaining_renewal_days: "",
        merchant_uuid: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      };
      this.$bvModal.show("modal-cabang");
    },
    preparePayload() {
      this.formPayload.selectedCountry !== null
        ? (this.formPayload.phone_country =
          this.formPayload.selectedCountry.country_code)
        : "ID";
      console.log(this.formPayload.phone_country);
      const form = new FormData();
      for (const key in this.formPayload) {
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (
            key !== "xendit_payment_date" &&
            key !== "remaining_renewal_days" &&
            key !== "selectedCountry"
          ) {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      return form;
    },
    editItem(id) {
      this.cleanUpForm();
      this.editId = id;
      this.$store
        .dispatch("cabang/getData", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          this.isLoading = false;
          const data = result.data.data;
          const formattedRenewalEndDate = moment(data.renewal_end_date).format(
            "YYYY-MM-DD"
          );

          this.formPayload = {
            name: data.name,
            address: data.address,
            city: data.city,
            postal_code: data.postal_code,
            phone: data.phone,
            code_branch: data.code_branch,
            renewal_status: data.renewal_status,
            renewal_end_date: formattedRenewalEndDate,
            xendit_payment_date: data.xendit_payment_date,
            remaining_renewal_days: data.remaining_renewal_days,
            selectedCountry: data.phone_country,
          };

          this.$store.commit("merchant/setFormPayload", {
            name: data.name,
            address: data.address,
            city: data.city,
            postal_code: data.postal_code,
            phone: data.phone,
            code_branch: data.code_branch,
            renewal_status: data.renewal_status,
            renewal_end_date: formattedRenewalEndDate,
            xendit_payment_date: data.xendit_payment_date,
            remaining_renewal_days: data.remaining_renewal_days,
            selectedCountry: data.phone_country,
          });
          this.$bvModal.show("modal-cabang");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async createItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      this.$store
        .dispatch("cabang/postData", {
          params: "",
          payload: form,
        })
        .then(async () => {
          successNotification(this, "Success", "Cabang berhasil di buat");
          this.cleanUpForm();
          this.$bvModal.hide("modal-cabang");
          this.isLoading = false;
          await this.getData();
        })
        .catch((error) => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, "Oops!", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
    async updateItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      form.append("_method", "PATCH");
      this.$store
        .dispatch("cabang/postData", {
          params: `/${this.editId}`,
          payload: form,
        })
        .then(async () => {
          await this.getData();
          successNotification(this, "Success", "Cabang berhasil di ubah!");
          this.cleanUpForm();
          this.isLoading = false;
          this.$bvModal.hide("modal-cabang");
          // window.location.href = '/master/users'
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            errorNotification(this, "Oops!", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    async exportReport() {
      const queryParams = this.filterExport;

      const response = await this.$http.get(
        "/super-admin/merchantBranch/export",
        {
          responseType: "blob",
          params: queryParams,
        }
      );

      const dateDownload = new Date();

      const timestamp = moment(dateDownload).format("YYYYMMDD");
      let fileName = "";

      fileName = `daftar-cabang-${timestamp}.xlsx`;
      FileSaver.saveAs(response.data, fileName);
      // console.log(response, ' report')
    },
  },
};
</script>
